import React from "react"
import Layout from "../components/layout"

export default function Error() {
  return (
      <Layout>
        <h1>Error 404</h1>
        <p>Page not found! :(</p>
      </Layout>    
  )  
    
}